<template>
    <div class="access-control">
        <el-breadcrumb class="crumb" separator="/">
            <el-breadcrumb-item>子账号管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="access-control-page">
            <div class="access-control-opts">
                <OptButton size="small" type="primary"  class="_create" @click="createAccount">创建用户
                </OptButton>
                <OptButton size="small" icon="icon iconfont iconbianzu1711"
                           style="margin-left:8px;color:#949494;transform: translateY(2px);min-width: 32px;width: 32px!important;padding: 6px 7px!important;"
                           @click="getUserlist"></OptButton>

            </div>
        </div>

        <el-table :data="tableData" v-loading="loadingData" style="width: 100%">
<!--            <el-table-column type="selection" width="60"></el-table-column>-->
            <el-table-column prop="username" label="用户账号" ></el-table-column>
            <el-table-column prop="description" label="描述" ></el-table-column>
            <el-table-column prop="status" label="状态" >
                <template slot-scope="{$index, row}">
                    <span v-show="row.status === 'NORMAL'">启用</span>
                    <span v-show="row.status === 'DISABLE'">停用</span>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" >
                <template slot-scope="{$index, row}">
                    {{ moment(row.createTime).format('YYYY-MM-DD HH:mm:ss') }}
                </template>
            </el-table-column>
            <el-table-column prop="bucketCount" label="可访问桶数量" >
                <template slot-scope="{$index, row}">
                    <el-popover
                        v-if="row.bucketCount >= 1"
                        placement="top"
                        width="400"
                        trigger="hover">
                        <div>{{ row.bucketNames && row.bucketNames.join(',') }}</div>
                        <span slot="reference" style="text-indent: 10px;">{{ row.bucketCount }}</span>
                    </el-popover>
                    <span style="text-indent: 10px;" v-else> {{ row.bucketCount }} </span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="240">
                <template slot-scope="{ $index, row }">
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <span class="table-action-button" @click="handleEdit(row)">编辑</span>
                        <span class="table-action-button" @click="handleManager(row)">权限管理</span>
                        <span class="table-action-button" @click="handleResetPwd(row)" >重置密码</span>
                        <span class="table-action-button" @click="handleDeleteUser(row)">删除</span>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <Pager class="bs-pager" v-model="pager" :sizes="[5, 10, 20]"
               @currentChange="handleCurrentChange" @sizeChange="handleSizeChange"></Pager>
        <CreateUser ref="createUserRef" @reload-list="getUserlist"/>
        <ResetPass ref="resetPassRef" @complete="getUserlist"/>
    </div>
</template>

<script>
import CreateUser from "@/views/accessControl/component/CreateUser";
import ResetPass from "@/views/components/ResetPass";
import moment from "moment";
export default {
    name: "access-control",
    components: { CreateUser, ResetPass },
    data () {
        return {
            tableData: [],
            loadingData: false,
            pager: {
                current: 1,
                size: 10,
                total: 0,
            }
        }
    },
    mounted() {
        this.getUserlist()
    },
    methods: {
        moment,
        getUserlist () { // /user/sub/list
            const {current, size} = this.pager;
            this.loadingData = true
            this.$postJson(`/accountUser/pageAccountUser`, { pageNum: current, pageSize: size })
            .then(res => {
                if (res.code === '200') {
                    this.tableData = res.data.records;
                    this.pager.total = res.data.total
                }
            })
            .finally(() => {
                this.loadingData = false
            })
        },
        handleManager ({bid}) {
            this.$router.push('/accessControl/manager?accountId=' + bid)
        },
        createAccount () {
            this.$refs.createUserRef.show()
        },
        handleEdit (row) {
            this.$refs.createUserRef.show(row)
        },
        handleResetPwd (row) {
            this.$refs.resetPassRef.show(row)
        },
        handleCurrentChange (cur) {
            this.pager.current = cur;
            this.getUserlist()
        },
        handleSizeChange (size) {
            this.pager.current = 1;
            this.pager.size = size;
            this.getUserlist()
        },
        handleDeleteUser (row) {
            this.$confirm(`<div><b>确认删除该子用户？</b><br/><span style="color: #999;">删除后，该子用户将无法继续使用蓝光存储服务。</span></div>`, {
                title: '提示',
                dangerouslyUseHTMLString: true
            }).then(() => {
                this.$deleteJson('/accountUser/deleteAccountUser', {accountUserId: row.bid})
                .then(res => {
                    if (res.code === '200') {
                        this.$message.success('操作成功')
                        this.getUserlist()
                    }
                })
            })
        }
    }
}
</script>

<style scoped lang="scss">
.access-control {
    margin: 16px 16px 10px;
    padding: 0 20px;
    background: #fff;
    text-align: left;
    min-height: calc(100% - 36px);
}

.access-control-opts {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 6px 0 22px;
}

</style>