<template>
    <div class="create-user">
        <el-dialog :title="title" :visible.sync="visible" append-to-body
                   destroy-on-close
                   :close-on-click-modal="false" width="600px">
            <el-form :model="form" ref="form" @submit.native.prevent
                     :rules="rules"
                     label-width="100px" style="">
                <div v-if="accountUserId">
                    <el-form-item  label="用户账号">
                        {{ row.username }}
                    </el-form-item>
                </div>
                <div v-else>
                    <el-form-item  label="用户账号" prop="username" >
                        <el-input placeholder="请输入内容" v-model.trim="form.username">
                            <template slot="prepend">{{preName}}@</template>
                        </el-input>
                    </el-form-item>
                </div>

                <el-form-item label="手机号码" prop="phone">
                    <el-input placeholder="请输入手机号码" v-model.trim="form.phone" />
                </el-form-item>
                <el-form-item label="邮箱地址" prop="mail">
                    <el-input placeholder="请输入邮箱地址" v-model.trim="form.mail" />
                </el-form-item>
                <el-form-item label="描述" prop="description">
                    <el-input placeholder="请输入内容" v-model.trim="form.description" />
                </el-form-item>
                <div v-if="accountUserId">
                    <el-form-item label="状态" prop="status" >
                        <el-switch
                            v-model="form.status"
                            class="inner-switch"
                            active-text="启用"
                            inactive-text="停用"
                            active-color="#7486D9"
                            inactive-color="#EBEBEB">
                        </el-switch>
                        <div class="tips">若关闭后，该子用户将无法继续使用{{ appName }}存储服务</div>
                    </el-form-item>
                </div>
                <div v-else>
                    <el-form-item label="获取密码凭证">
                        <el-radio-group v-model="form.showPassword" style="margin-top: 10px;">
                            <el-radio :label="'web'">创建完成后显示自动生成的登录密码凭证</el-radio>
                            <div style="height: 10px;"></div>
                            <el-radio :label="'mail'">将自动生成的登录密码发送至子用户邮箱</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </div>


            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="handleCancel" >取消</el-button>
                <el-button size="small" type="primary" :loading="loading" @click="handleSubmit">确定</el-button>
            </span>
        </el-dialog>

        <ShowPass ref="showPassRef"/>
    </div>
</template>

<script>
import copy from 'copy-to-clipboard'
import { mapGetters } from 'vuex'
import ShowPass from './showPass'
export default {
    name: "create-user",
    components: {ShowPass},
    data() {
        return {
            visible: false,
            title: '创建子用户',
            accountUserId: '',
            form: {
                username: '',
                phone: '',
                mail: '',
                description: '',
                showPassword: 'web',
                status: true
            },
            loading: false,
            rules: {
                username: [
                    {required: true, message: '长度3-10位，仅支持大小写字母、数字、_、@、.', min: 3, max: 10},
                    {pattern: /^[0-9a-zA-Z_@\.]+$/, message: '长度3-10位，仅支持大小写字母、数字、_、@、.'}
                ],
                phone: [
                    {required: true, message: '请输入手机号'},
                    {pattern: /^1[3-9]\d{9}$/, message: '格式错误', trigger: 'blur'}
                ],
                mail: [
                    {required: true, message: '请输入邮箱地址，最多50个字符', max: 50},
                    {pattern: /^[a-zA-Z\d_]+@[a-zA-Z\d]+(\.[a-zA-Z]{2,4})+$/, message: '格式错误', trigger: 'blur'}
                ],
                description: [
                    {max: 50, message: '最多输入50个字符'},
                    {pattern: /^[\u4e00-\u9fa5\da-zA-Z]*$/, message: '只能输入数字、字母或中文'}
                ]
            },
            row: {}
        }
    },
    computed: {
        ...mapGetters(['userData', 'appName']),
        preName () {
            const {userData} = this;
            if (userData ) {
                return userData.username.slice(0, 20)
            }
            return ''
        }
    },
    methods: {
        show(row) {
            this.visible = true
            console.log('子账号：', row)
            if (row && row.bid) {
                this.title = '编辑子用户'
                this.form.phone = row.phone
                this.form.mail = row.mail
                this.form.description = row.description
                this.form.showPassword = row.showPassword
                this.accountUserId = row.bid
                this.form.status = row.status === 'NORMAL'
                this.row = row
            } else {
                this.title = '创建子用户'
                this.form.username = ''
                this.accountUserId = ''
                this.form.phone = ''
                this.form.mail = ''
                this.form.description = ''
                this.form.showPassword = 'web'
                setTimeout(() => { this.$refs.form.clearValidate()}, 0)
            }
        },
        handleCancel () {
            this.visible = false
        },

        handleSubmit () {
            this.$refs.form.validate(valid => {
                if (!valid) {
                    return
                }
                const {form, userData, preName, accountUserId, row} = this;
                const data = {...form, username: preName + '@' + form.username, status: form.status ? 'NORMAL' : 'DISABLE'}
                let url = '/accountUser/createAccountUser'
                let request = null
                this.loading = true
                if (accountUserId) {
                    data.accountUserId = accountUserId
                    data.username = row.username
                    url = '/accountUser/updateAccountUser'
                    request = this.$postJson(url, data)
                } else {
                    delete  data.status
                    request = this.$putJson(url, data)
                }


                request
                .then(res => {
                    if (res.code === '200') {
                        this.visible = false
                        if (!accountUserId && data.showPassword === 'web') { //
                            this.$refs.showPassRef.show(res.data.username, res.data.plainPassword)
                        } else {
                            this.$message.success('操作成功')
                        }
                        this.$emit('reload-list')
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            })
        }
    }
}
</script>

<style scoped>
.tips {
    font-size: 12px; color: #999;
    line-height: 18px;
    margin-top: 4px;
}
</style>
