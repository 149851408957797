<template>
    <el-dialog title="获取密码" :visible.sync="visible2" append-to-body
               :close-on-click-modal="false" width="484px">
        <div class="account-info">账号：{{ account }}</div>
        <div class="account-info">密码：********</div>
        <div class="tips">说明：用户创建成功，单击 “一键复制” 可复制账号密码，单击“取消”后将无法获取该密码。</div>

        <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="handleCancel2">取消</el-button>
                <el-button size="small" type="primary" @click="handleCopy">一键复制</el-button>
            </span>
    </el-dialog>
</template>

<script>
import copy from "copy-to-clipboard";

export default {
    name: "showPass",
    data() {
        return {
            visible2: false,
            account: '',
            password: ''
        }
    },
    methods: {
        show(account, password) {
            this.visible2 = true
            this.account = account
            this.password = password
        },
        handleCancel2 () {
            this.visible2 = false
        },
        handleCopy () {
            let text = '账号： ' + this.account + '\r\n 密码： ' + this.password
            copy(text)
            this.$message.success('复制成功')
            this.visible2 = false
        },
    }
}
</script>

<style scoped>
.account-info {
    font-size: 14px;
    color: #666;
    line-height: 20px;
    margin-bottom: 16px;
}
.tips {
    font-size: 12px; color: #666;
    line-height: 18px;
    margin-top: 4px;
}
</style>